import { v4 as uuidv4 } from "uuid";

export function getOrDefineAnonymousUserId() {
  const storedId = localStorage.getItem("anonymousUserId");

  if (!storedId) {
    const newId = uuidv4();
    localStorage.setItem("anonymousUserId", newId);
    return newId;
  }

  return storedId;
}
