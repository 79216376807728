import Box from "@mui/material/Box";
import React, { useEffect } from "react";
import BACKGROUND_FRIENDS_IN_FIELD from "../assets/background-picture-friends-in-field.jpg";
import "./PupilsPage.css";
import useWindowDimensions from "../hooks/windowDimensions";
import { Stack, Typography } from "@mui/material";
import { DividerWithText } from "../components/shared/DividerWithText";
import { FilterPreSelection } from "../components/for-pupils/FilterPreSelection";
import { Footer } from "../components/footer/Footer";

export const MAX_BLUE_LINEAR_GRADIENT = "#68B2E4";
export const MAX_GREEN_LINEAR_GRADIENT = "#AFCB3A";

export default function PupilsPage() {
  const [scaleBasedOnWidth, setScaleBasedOnWidth] = React.useState(true);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (width < 1520) {
      setScaleBasedOnWidth(false);
    } else {
      setScaleBasedOnWidth(true);
    }
  }, [width]);

  return (
    <Box>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "530px",
          backgroundImage: `linear-gradient(to right, ${MAX_BLUE_LINEAR_GRADIENT}, ${MAX_GREEN_LINEAR_GRADIENT}), url(${BACKGROUND_FRIENDS_IN_FIELD})`,
          backgroundSize: `contain, ${
            scaleBasedOnWidth ? "100% auto" : "auto 100%"
          }`,
          backgroundPosition: "center center, center center",
          backgroundBlendMode: "multiply",
          backgroundRepeat: "no-repeat, no-repeat",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            textAlign: "center",
          }}
        >
          <Stack>
            <DividerWithText text={"Kostenfrei & ohne Anmeldung"} />

            {/*  TODO make it closer to the initial design (bold + font size) */}
            <Typography variant={"h2"}>
              Einfach. Unterstützung. Finden.
            </Typography>
            <DividerWithText />
          </Stack>
        </Box>
      </Box>
      <Box
        sx={{
          position: "relative",
          transform: "translateY(-140px)",
        }}
      >
        <FilterPreSelection />
        <Footer />
      </Box>
    </Box>
  );
}
