import { DARK_GRAY } from "../colors";
import React from "react";
import { Box } from "@mui/material";

const USER_PROFILE_IMAGE_BACKGROUND_COLORS = [DARK_GRAY];

export function UserProfileImage(props: {
  firstName: string;
  lastName: string;
  size?: number;
  doNotUseColor?: boolean;
}) {
  const { firstName, lastName, size = 40, doNotUseColor = true } = props;

  const backgroundColor = undefined;
  // for now constant background color is sufficient
  // const backgroundColor = calculateIconColor(firstName, lastName);

  return (
    <Box
      sx={{
        width: size,
        height: size,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        backgroundColor: doNotUseColor ? "grey.500" : backgroundColor,
        color: "white",
        fontSize: (16 * size) / 40,
        fontWeight: "bold",
      }}
    >
      {firstName[0]?.toUpperCase() + (lastName[0] ?? "").toUpperCase()}
    </Box>
  );
}

function calculateIconColor(firstName: string, lastName: string) {
  const name = firstName + lastName;
  const insecureHash = calcInsecureHashCode(name);
  const colorIndex = insecureHash % USER_PROFILE_IMAGE_BACKGROUND_COLORS.length;

  return USER_PROFILE_IMAGE_BACKGROUND_COLORS[colorIndex];
}

/**
 * {@link https://gist.github.com/hyamamoto/fd435505d29ebfa3d9716fd2be8d42f0}
 */
function calcInsecureHashCode(str: string): number {
  for (var i = 0, h = 0; i < str.length; i++)
    h = (Math.imul(31, h) + str.charCodeAt(i)) | 0;
  return Math.abs(h);
}
