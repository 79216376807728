import {
  Organization_Insert_Input,
  OrganizationFragment,
} from "../../generated/graphql";

export interface OrganizationFormValues {
  name: string;
  logo_url: string;
  website_url: string;
}

export type ErrorProps = {
  error: boolean | undefined;
  helperText: string | boolean | undefined;
};

export function getInitialValuesFromOrganization(
  organization: OrganizationFragment | null
): OrganizationFormValues {
  return {
    name: organization?.name ?? "",
    logo_url: organization?.logo_url ?? "",
    website_url: organization?.website_url ?? "",
  };
}

export function convertFormikValuesToOrganizationUpsertionInput(
  organization: OrganizationFragment | null,
  values: OrganizationFormValues
): Organization_Insert_Input {
  const updatedOrganizationWithNullValues = {
    id: organization?.id ?? null,
    name: values.name,
    logo_url: values.logo_url ?? null,
    website_url: values.website_url ?? null,
  };

  return removeEmptyStringsFromInput(updatedOrganizationWithNullValues);
}

/**
 * Empty Strings are written as empty strings to the database instead of null
 * -> remove them from the input to set them to null
 */
function removeEmptyStringsFromInput(
  updatedOrganization: Organization_Insert_Input
) {
  return Object.fromEntries(
    Object.entries(updatedOrganization).filter(
      ([_, value]) => value != null && value !== ""
    )
  );
}
