import { Navigate, Outlet, useLocation, useRoutes } from "react-router-dom";
import React, {
  ElementType,
  lazy,
  Suspense,
  useContext,
  useEffect,
} from "react";
import AuthGuard from "./authentication/AuthGuard";
import { LoginCallback } from "./authentication/LoginCallback";
import { CircularProgress } from "@mui/material";
import NavigationBar from "./pages/NavigationBar";
import AddOrEditOrganizationPage from "./pages/AddOrEditOrganizationPage";
import ImpressumPage from "./pages/ImpressumPage";
import DataProtectionDeclarationPage from "./pages/DataProtectionDeclarationPage";
import { UserContext } from "./contexts/UserContext";
import axios from "axios";
import { SERVER_URL } from "./index";
import { getOrDefineAnonymousUserId } from "./shared/analysis.utils";
import { Footer } from "./components/footer/Footer";

const Loadable = (Component: ElementType) => (props: any) => {
  return (
    <Suspense fallback={<CircularProgress />}>
      <Component {...props} />
    </Suspense>
  );
};

const FundingProgramsPage = Loadable(
  lazy(() => import("./pages/FundingProgramsPage"))
);
const AddOrEditFundingProgramPage = Loadable(
  lazy(() => import("./pages/AddOrEditFundingProgramPage"))
);
const OrganizationsPage = Loadable(
  lazy(() => import("./pages/OrganizationsPage"))
);
const PupilsPage = Loadable(lazy(() => import("./pages/PupilsPage")));
const AboutUsPage = Loadable(lazy(() => import("./pages/AboutUsPage")));
const NotFoundPage = Loadable(lazy(() => import("./pages/NotFoundPage")));

// TODO add app Layout

export default function Router() {
  const location = useLocation();

  const { anonymousUserId, setAnonymousUserId } = useContext(UserContext);

  useEffect(
    function sendInteractionAnalyticsDetails() {
      if (!anonymousUserId) {
        setAnonymousUserId(getOrDefineAnonymousUserId());
      }

      if (
        anonymousUserId?.length &&
        anonymousUserId != "null" &&
        anonymousUserId != null
      ) {
        try {
          const sendRequest = async () => {
            axios.post(SERVER_URL + "/analysis/interaction", {
              visitedPageUrl: location.pathname,
              anonymousUserId: anonymousUserId,
            });
          };

          sendRequest();
        } catch (error) {
          console.error("Failed to send route change request", error);
        }
      }
    },
    [location, anonymousUserId]
  );

  return useRoutes([
    {
      path: "/",
      element: (
        <AuthGuard>
          <NavigationBar />
          <Outlet />
        </AuthGuard>
      ),
      children: [
        {
          path: "/funding-programs/:fundingProgramId/edit",
          element: (
            <AuthGuard>
              <AddOrEditFundingProgramPage isEdit={true} />
            </AuthGuard>
          ),
        },
        {
          path: "/funding-programs/add",
          element: (
            <AuthGuard>
              <AddOrEditFundingProgramPage />
            </AuthGuard>
          ),
        },

        { path: "/organizations", element: <OrganizationsPage /> },
        {
          path: "/organizations/:organizationId/edit",
          element: (
            <AuthGuard>
              <AddOrEditOrganizationPage isEdit={true} />
            </AuthGuard>
          ),
        },
        {
          path: "/organizations/add",
          element: (
            <AuthGuard>
              <AddOrEditOrganizationPage isEdit={false} />
            </AuthGuard>
          ),
        },
      ],
    },

    {
      path: "/for-pupils",
      element: (
        <AuthGuard>
          <NavigationBar />
          <PupilsPage />
        </AuthGuard>
      ),
    },
    { element: <Navigate to="/for-pupils" replace />, index: true },
    {
      path: "/funding-programs",
      element: (
        <AuthGuard>
          <NavigationBar />
          <FundingProgramsPage />
          <Footer isReducedFooter={true} />
        </AuthGuard>
      ),
    },
    {
      path: "/about-us",
      element: (
        <>
          <NavigationBar />
          <AboutUsPage />
          <Footer />
        </>
      ),
    },

    { path: "/callback", element: <LoginCallback /> },
    { path: "/impressum", element: <ImpressumPage /> },
    {
      path: "/datenschutzerklaerung",
      element: <DataProtectionDeclarationPage />,
    },
    { path: "/404", element: <NotFoundPage /> },
    { path: "*", element: <Navigate to={"/404"} replace /> },
  ]);
}
