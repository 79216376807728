import {
  ApolloClient,
  ApolloLink,
  concat,
  HttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { GRAPHQL_ENDPOINT, GRAPHQL_WS_ENDPOINT } from "./index";
import { onError } from "@apollo/client/link/error";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { isProduction } from "./utils";

export function getApolloClient(authLink: ApolloLink, getHeaders: () => any) {
  const httpUri = process.env.REACT_APP_GRAPHQL_ENDPOINT ?? GRAPHQL_ENDPOINT;
  const wsUri =
    process.env.REACT_APP_GRAPHQL_WS_ENDPOINT ?? GRAPHQL_WS_ENDPOINT;

  const httpLink = new HttpLink({ uri: httpUri });
  const wsLink = new GraphQLWsLink(
    createClient({
      url: wsUri,
      connectionParams: async () => {
        return {
          headers: {
            ...(await getHeaders()),
          },
          reconnect: true,
          lazy: true,
        };
      },
    })
  );

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    }

    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
    }
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    concat(errorLink, httpLink)
  );

  return new ApolloClient({
    link: concat(authLink, splitLink),
    cache: new InMemoryCache(),
    connectToDevTools: !isProduction(),
  });
}
