import React from "react";
import { Link } from "@mui/material";

function LinkColorless(props: {
  href: string;
  displayText: string;
  title: string;
}) {
  const { href, displayText, title } = props;

  return (
    <Link
      href={href}
      sx={{ color: "inherit", textDecoration: "none" }}
      title={title}
    >
      {displayText}{" "}
    </Link>
  );
}

export default LinkColorless;
