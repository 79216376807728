import { Box } from "@mui/material";
import React from "react";
export function OrganizationLogoPreview(props: {
  logo_url: string | undefined | null;
}) {
  const { logo_url } = props;

  if (!logo_url) {
    return <></>;
  }
  return (
    <Box sx={{ ml: 2, border: "1px solid grey", borderRadius: 1 }}>
      <img
        src={logo_url}
        alt="Logo"
        style={{
          maxWidth: "200px",
          maxHeight: "150px",
          width: "auto",
          height: "auto",
        }}
      />
    </Box>
  );
}
