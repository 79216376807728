import React from "react";
import Box from "@mui/material/Box";
import { Divider, SxProps, Typography } from "@mui/material";

export const DIVIDER_MARGIN = 2;

export function DividerWithText(props: {
  text?: string;
  color?: string;
  dividerProps?: SxProps;
}) {
  const { text, color, dividerProps } = props;

  const displayColor = color ?? "white";

  const appliedDividerProps = {
    backgroundColor: displayColor,
    marginTop: DIVIDER_MARGIN,
    marginBottom: DIVIDER_MARGIN,
    flexGrow: 1,
  };

  if (!text) {
    return <Divider sx={appliedDividerProps} />;
  }

  return (
    <Box display="flex" alignItems="center">
      {/*  TODO Divider should not take the full width on small screens */}
      <Divider sx={appliedDividerProps} />
      <Typography
        variant="body1"
        sx={{
          margin: "0 1em",
          textTransform: "uppercase",
          fontSize: "1.1rem",
          color: displayColor,
        }}
      >
        {text}
      </Typography>
      <Divider sx={appliedDividerProps} />
    </Box>
  );
}
