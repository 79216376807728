import {
  Enum_Gender_Enum,
  Enum_Grade_Type_Enum,
  Funding_Program_Insert_Input,
  FundingProgramFragment,
} from "../../generated/graphql";
import moment from "moment";

export interface FundingProgramFormValues {
  name: string;

  organization_ids: string[] | null;
  website: string | null;
  short_description: string | null;
  application_types: ApplicationType[] | null;
  application_start: moment.Moment | null;
  application_end: moment.Moment | null;
  support_duration: string | null;

  type: FundingType[] | null;
  characteristics: Characteristic[] | null;
  technical_focus: string | null;
  miscellaneous: string | null;
  location: LocationType[] | null;

  gender: Enum_Gender_Enum | null;
  min_age: number | null;
  max_age: number | null;
  prerequisites: Prerequisite[] | null;

  school_types: SchoolType[] | null;
  min_grade: number | null;
  max_grade: number | null;
  min_grade_type: Enum_Grade_Type_Enum | null;
  max_grade_type: Enum_Grade_Type_Enum | null;
  federal_states: FederalStates[] | null;
  zip_areas: string[] | null;
}

export type ErrorProps = {
  error: boolean | undefined;
  helperText: string | boolean | undefined;
};

export function getInitialValuesFromFundingProgram(
  fundingProgram: FundingProgramFragment | null
): FundingProgramFormValues {
  return {
    name: fundingProgram?.name ?? "",

    organization_ids: fundingProgram?.organization_ids ?? null,
    website: fundingProgram?.website ?? "",
    short_description: fundingProgram?.short_description ?? "",
    application_types:
      fundingProgram?.application_types?.map(
        // @ts-ignore only saved keys from enum
        (applicationType: any) => ApplicationType[applicationType]
      ) ?? null,
    federal_states:
      fundingProgram?.federal_states?.map(
        // @ts-ignore only saved keys from enum
        (federalStateKey: any) => FederalStates[federalStateKey]
      ) ?? null,
    zip_areas: fundingProgram?.zip_areas ?? [],
    application_start: fundingProgram?.application_start
      ? moment(fundingProgram.application_start)
      : null,
    application_end: fundingProgram?.application_end
      ? moment(fundingProgram.application_end)
      : null,
    support_duration: fundingProgram?.support_duration ?? "",

    type:
      fundingProgram?.type?.map(
        //@ts-ignore expected to only contain keys from enum
        (prerequisiteKey: any) => FundingType[prerequisiteKey]
      ) ?? null,
    characteristics:
      fundingProgram?.characteristics?.map(
        //@ts-ignore expected to only contain keys from enum
        (prerequisiteKey: any) => Characteristic[prerequisiteKey]
      ) ?? null,
    technical_focus: fundingProgram?.technical_focus ?? "",
    miscellaneous: fundingProgram?.miscellaneous ?? "",
    location:
      fundingProgram?.location?.map(
        //@ts-ignore expected to only contain keys from enum
        (prerequisiteKey: any) => LocationType[prerequisiteKey]
      ) ?? null,

    gender: fundingProgram?.gender ?? null,
    min_age: fundingProgram?.min_age ?? null,
    max_age: fundingProgram?.max_age ?? null,
    prerequisites:
      fundingProgram?.prerequisites?.map(
        //@ts-ignore expected to only contain keys from enum
        (prerequisiteKey: any) => Prerequisite[prerequisiteKey]
      ) ?? null,

    school_types:
      fundingProgram?.school_types?.map(
        // @ts-ignore only saved keys from enum
        (schoolTypeKey: string) => SchoolType[schoolTypeKey]
      ) ?? null,
    min_grade: fundingProgram?.min_grade ?? null,
    max_grade: fundingProgram?.max_grade ?? null,
    min_grade_type: fundingProgram?.min_grade_type ?? null,
    max_grade_type: fundingProgram?.max_grade_type ?? null,
  };
}

export function convertFormikValuesToUpsertionInput(
  fundingProgram: FundingProgramFragment | null,
  values: FundingProgramFormValues
): Funding_Program_Insert_Input {
  const updatedFundingProgramWithNullValues = {
    id: fundingProgram?.id ?? null,
    name: values.name,

    organization_ids: values.organization_ids ?? null,
    website: values.website,
    short_description: values.short_description,
    application_types: values.application_types?.map(
      (enumValue) => getEnumKeyFromValue(ApplicationType, enumValue) ?? null
    ),
    federal_states: values.federal_states?.map(
      (enumValue) => getEnumKeyFromValue(FederalStates, enumValue) ?? null
    ),
    zip_areas: values.zip_areas,
    application_start: values.application_start
      ? moment(values.application_start).toISOString(true)
      : null,
    application_end: values.application_end
      ? moment(values.application_end).toISOString(true)
      : null,
    support_duration: values.support_duration,

    type: values.type?.map(
      (enumValue) => getEnumKeyFromValue(FundingType, enumValue) ?? null
    ),
    characteristics: values.characteristics?.map(
      (enumValue) => getEnumKeyFromValue(Characteristic, enumValue) ?? null
    ),
    technical_focus: values.technical_focus,
    miscellaneous: values.miscellaneous,
    location: values.location?.map(
      (enumValue) => getEnumKeyFromValue(LocationType, enumValue) ?? null
    ),

    gender: values.gender,
    min_age: values.min_age,
    max_age: values.max_age,
    prerequisites: values.prerequisites?.map(
      (enumValue) => getEnumKeyFromValue(Prerequisite, enumValue) ?? null
    ),

    school_types: values.school_types?.map(
      (enumValue) => getEnumKeyFromValue(SchoolType, enumValue) ?? null
    ),
    min_grade: values.min_grade,
    max_grade: values.max_grade,
    min_grade_type: values.min_grade_type,
    max_grade_type: values.max_grade_type,
  };

  return removeEmptyStringsFromInput(updatedFundingProgramWithNullValues);
}

function getEnumKeyFromValue(Enum: any, value: any) {
  return Object.keys(Enum)[Object.values(Enum).indexOf(value)];
}

/**
 * Empty Strings are written as empty strings to the database instead of null
 * -> remove them from the input to set them to null
 */
function removeEmptyStringsFromInput(
  updatedFundingProgram: Funding_Program_Insert_Input
) {
  return Object.fromEntries(
    Object.entries(updatedFundingProgram).filter(
      ([_, value]) => value != null && value !== ""
    )
  );
}

export enum FederalStates {
  BUNDESWEIT = "Bundesweit",
  NORDRHEIN_WESTFALEN = "Nordrhein-Westfalen",
  HAMBURG = "Hamburg",
  BAYERN = "Bayern",

  BERLIN = "Berlin",
  SACHSEN = "Sachsen",
  HESSEN = "Hessen",
  RHEINLAND_PFALZ = "Rheinland-Pfalz",
  NIEDERSACHSEN = "Niedersachsen",
  SAARLAND = "Saarland",
  MECKLENBURG_VORPOMMERN = "Mecklenburg-Vorpommern",
  SCHLESWIG_HOLSTEIN = "Schleswig-Holstein",
  SACHSEN_ANHALT = "Sachsen-Anhalt",
  BRANDENBURG = "Brandenburg",
  BREMEN = "Bremen",
  BADEN_WUERTTEMBERG = "Baden-Württemberg",
  THUERINGEN = "Thüringen",
}

export enum SchoolType {
  GRUNDSCHULE = "Grundschule",
  GYMNASIUM = "Gymnasium",
  GESAMTSCHULE = "Gesamtschule",
  WEITERBILDUNGSKOLLEG = "Weiterbildungskolleg",
  PRIMUS_SCHULE = "Primus Schule",
  SEKUNDARSCHULE = "Sekundarschule",
  REALSCHULE = "Realschule",
  HAUPTSCHULE = "Hauptschule",
  INDEPENDENT = "Unabhängig",
  ALLGEMEINBILDENDE_SCHULEN = "Allgemeinbildende Schule",
  WEITERFUEHRENDE_SCHULE = "Weiterführende Schule",
  STADTTEILSCHULE = "Stadtteilschule",
}

export enum Prerequisite {
  SOZIALE_HERAUSFORDERUNGEN = "Soziale Herausforderungen",
  FINANZIELLE_BEDUERFTIGKEIT = "Finanzielle Bedürftigkeit",
  GESUNDHEITLICHE_BEEINTRAECHTIGUNGEN = "Gesundheitliche Beeinträchtigungen",
  MEHRSPRACHIGKEIT = "Mehrsprachigkeit",
  MIGRATIONSHINTERGRUND = "Migrationshintergrund",
  FLUCHTERFAHRUNG = "Fluchterfahrung",
  BEGABUNG = "Begabung",
  BERUFSAUSBILDUNG = "Berufsausbildung",
  ERSTAKADEMIKER = "Erstakademiker:in",
  EHRENAMT = "Ehrenamt",
  AUSREICHENDE_SCHULISCHE_LEISTUNGEN = "Ausreichende schulische Leistungen",
}

export enum Characteristic {
  BERUFSERFAHRUNG = "Berufserfahrung",
  AUSLANDSAUFENTHALT = "Auslandsaufenthalt",
  UNIVERSITAETSBESUCHE = "Universitätsbesuche",
  PERSOENLICHKEITSENTWICKLUNG = "Persönlichkeitsentwicklung",
  BEWERBUNGSUNTERSTUETZUNG = "Bewerbungsunterstützung",
  ELTERNFOERDERUNG = "Elternförderung",
}

export enum LocationType {
  ON_SITE = "Vor Ort",
  REMOTE = "Digital",
}

export enum FundingType {
  IDEELLE_FOERDERUNG = "Ideelle Förderung",
  NACHHILFE = "Nachhilfe",
  MENTORING = "Mentoring",
  AUSLANDSAUFENTHALT = "Auslandsaufenthalt",
  FINANZIELLE_FOERDERUNG = "Finanzielle Förderung",
  FRUEHSTUDIUM = "Frühstudium",
}

export enum ApplicationType {
  DURCH_VORSCHLAG = "Durch Vorschlag",
  FORMLOS = "Formlos",
  FORMULAR = "Formular",
  MOTIVATIONSSCHREIBEN = "Motivationsschreiben",
  NUR_INTERNE_BEWERBUNG_MOEGLICH = "NurInterneBewerbungMoeglich",
  UNBEKANNT = "Unbekannt",
}
