import Typography from "@mui/material/Typography";
import React from "react";

export function ChancenbuddyLettering() {
  return (
    <Typography
      sx={{
        fontFamily: "Signika, sans-serif",
        fontSize: 40,
        color: "#00ACFF",
      }}
    >
      Chancenbuddy
    </Typography>
  );
}
