import { useLogto } from "@logto/react";
import { useContext, useMemo, useState } from "react";
import { setContext } from "@apollo/client/link/context";
import { getApolloClient } from "../apollo";
import { User, USER_ROLE_PRIORITY, UserContext, UserRole } from "./UserContext";
import { ApolloProvider } from "@apollo/client";
import { PRODUCTION_HASURA_URL } from "../index";

export default function LogtoApolloProvider(props: any) {
  const { getAccessToken } = useLogto();

  const { user } = useContext(UserContext);

  const [tokenInfo, setTokenInfo] = useState<{
    token: string;
    expiresAt: number;
  } | null>(null);

  // useMemo to ensure that the client is only created once.
  const apolloClient = useMemo(() => {
    const authLink = setContext(async (_, { headers }) => {
      const hasuraHeaders = {
        ...headers,
        ...(await getHeaders()),
      };

      return {
        headers: hasuraHeaders,
      };
    });

    return getApolloClient(authLink, getHeaders);
  }, [getAccessToken, user]);

  async function getHeaders() {
    let token = tokenInfo?.token;
    const currentTime = Date.now();

    if (!token || !tokenInfo || tokenInfo.expiresAt <= currentTime) {
      token = await getAccessToken(PRODUCTION_HASURA_URL);

      // TODO retrieve expiration from token instead
      const FORTY_MINUTES = 40 * 60 * 1000;
      const expiresAt = currentTime + FORTY_MINUTES;

      setTokenInfo({ token: token!, expiresAt });
    }

    return {
      ...getExpectedRoleHeader(user),
      Authorization: `Bearer ${token ?? ""}`,
    };
  }

  return <ApolloProvider client={apolloClient} {...props} />;
}

function getExpectedRoleHeader(user: User | null) {
  if (user === null) {
    console.warn("User is not authenticated");
  }

  const expectedRole = getExpectedRole(user?.roles);
  return { "Expected-Role": expectedRole };
}

export function getExpectedRole(availableRoles?: UserRole[]): UserRole {
  if (!availableRoles?.length) {
    return "not_authenticated_user";
  }

  const sortedRoles = sortUserRolesByPriority(availableRoles);
  const expectedRole = sortedRoles[0];
  return expectedRole as UserRole;
}

function sortUserRolesByPriority(roles: string[]) {
  return roles
    .filter((role) => USER_ROLE_PRIORITY.includes(role))
    .sort(
      (roleA, roleB) =>
        USER_ROLE_PRIORITY.indexOf(roleA) - USER_ROLE_PRIORITY.indexOf(roleB)
    );
}
