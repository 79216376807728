import React from "react";
import "./WaveSVG.css";
import Box from "@mui/material/Box";
import { BLACK, DARK_BLUE, LIGHT_BLUE } from "../../colors";
import { PreSelection, SHORT_INFOS_ELEMENT_ID } from "./PreSelection";
import { PreSelectionGroup } from "./PreSelectionGroup";
import { Button, Stack, Typography } from "@mui/material";
import {
  FederalStates,
  FundingType,
} from "../funding_program/EditFundingProgramUtils";
import { useGetPreSelectionDetailsQuery } from "../../generated/graphql";
import { useNavigate } from "react-router-dom";
import { getEnumKeyFromValue } from "../../utils";

/**
 * LearningSVG -> Nachhilfe
 */

export const preSelectionGrade = [
  { value: 1, label: "1. Klasse" },
  { value: 2, label: "2. Klasse" },
  { value: 3, label: "3. Klasse" },
  { value: 4, label: "4. Klasse" },
  { value: 5, label: "5. Klasse" },
  { value: 6, label: "6. Klasse" },
  { value: 7, label: "7. Klasse" },
  { value: 8, label: "8. Klasse" },
  { value: 9, label: "9. Klasse" },
  { value: 10, label: "10. Klasse" },
  { value: 11, label: "11. Klasse" },
  { value: 12, label: "12. Klasse" },
  { value: 13, label: "13. Klasse" },
];

export const ICON_FINANCIAL_SUPPORT = "ph:hand-coins-bold";
export const ICON_AUSLANDSAUFENTHALT = "ph:globe-hemisphere-west-bold";
export const ICON_FRUEHSTUDIUM = "ph:graduation-cap-bold";
export const ICON_PERSOENLICHE_ENTWICKLUNG = "ph:hand-heart-bold";
export const ICON_NACHHILFE = "ph:chalkboard-teacher-bold";

export const ICON_GRADE_SELECTION = "fa-solid:chalkboard-teacher";
export const ICON_FEDERAL_STATE_SELECTION = "grommet-icons:map";

export function getIconByFundingProgramType(type?: FundingType) {
  switch (type) {
    case FundingType.FINANZIELLE_FOERDERUNG:
      return ICON_FINANCIAL_SUPPORT;
    case FundingType.AUSLANDSAUFENTHALT:
      return ICON_AUSLANDSAUFENTHALT;
    case FundingType.FRUEHSTUDIUM:
      return ICON_FRUEHSTUDIUM;
    case FundingType.MENTORING:
    case FundingType.IDEELLE_FOERDERUNG:
      return ICON_PERSOENLICHE_ENTWICKLUNG;
    case FundingType.NACHHILFE:
      return ICON_NACHHILFE;
    default:
      return "";
  }
}

export function FilterPreSelection() {
  const { data } = useGetPreSelectionDetailsQuery();
  const navigate = useNavigate();

  const schulalltagPreSelections: PreSelection[] = [
    {
      title: "Nachhilfe",
      subtitle: data
        ? `${data?.nachilfe.aggregate?.count} Wissensvermittler`
        : undefined,
      icon: getIconByFundingProgramType(FundingType.NACHHILFE),
      key: getEnumKeyFromValue(FundingType, FundingType.NACHHILFE),
    },
    {
      title: "Persönliche Entwicklung",
      subtitle: data
        ? `${data?.persoenlicheEntwicklung.aggregate?.count} Mentoren & Workshops`
        : undefined,
      icon: getIconByFundingProgramType(FundingType.MENTORING),
      key: getEnumKeyFromValue(FundingType, FundingType.MENTORING),
    },
    {
      title: "Finanzielle Förderung",
      subtitle: data
        ? `${data?.finanzielleFoerderung.aggregate?.count} Stipendien`
        : undefined,
      icon: getIconByFundingProgramType(FundingType.FINANZIELLE_FOERDERUNG),
      key: getEnumKeyFromValue(FundingType, FundingType.FINANZIELLE_FOERDERUNG),
    },
  ];

  const furtherSupportingOptionsPreSelections: PreSelection[] = [
    {
      title: FundingType.AUSLANDSAUFENTHALT,
      subtitle: data
        ? `${data?.auslandsaufenthalte.aggregate?.count} Angebote`
        : undefined,
      icon: getIconByFundingProgramType(FundingType.AUSLANDSAUFENTHALT),
      key: getEnumKeyFromValue(FundingType, FundingType.AUSLANDSAUFENTHALT),
    },
    {
      title: FundingType.FRUEHSTUDIUM,
      subtitle: data
        ? `${data?.studiumVorbereitung.aggregate?.count} Angebote`
        : undefined,
      icon: getIconByFundingProgramType(FundingType.FRUEHSTUDIUM),
      key: getEnumKeyFromValue(FundingType, FundingType.FRUEHSTUDIUM),
    },
    // {
    //   title: "Berufsvorbereitung",
    //   subtitle: "TODO: welche angebote sind das?",
    //   icon: "ph:strategy-bold",
    // },
  ];

  const preSelectionFederalState = [
    {
      value:
        getEnumKeyFromValue(FederalStates, FederalStates.NORDRHEIN_WESTFALEN) ??
        "",
      label: FederalStates.NORDRHEIN_WESTFALEN,
    },
    {
      value: getEnumKeyFromValue(FederalStates, FederalStates.HAMBURG) ?? "",
      label: FederalStates.HAMBURG,
    },
    {
      value: getEnumKeyFromValue(FederalStates, FederalStates.BAYERN) ?? "",
      label: FederalStates.BAYERN,
    },
    {
      value: "ANDERES_BUNDESLAND",
      label: "Anderes Bundesland",
    },
  ];

  const shortInfos: PreSelection[] = [
    {
      title: "In welche Klasse gehst du?",
      icon: ICON_GRADE_SELECTION,
      dropdownSelection: {
        selectionOptions: preSelectionGrade,
        selectedValue: undefined,
        placeholder: "Klasse wählen",
      },
    },
    {
      title: "Wo gehst du zur Schule?",
      icon: ICON_FEDERAL_STATE_SELECTION,
      dropdownSelection: {
        selectionOptions: preSelectionFederalState,
        selectedValue: undefined,
        placeholder: "Bundesland wählen",
      },
    },
  ];

  function getIndexByIconName(
    iconName: string,
    preSelections: PreSelection[]
  ): number {
    return preSelections.findIndex(
      (preSelection) => preSelection.icon === iconName
    );
  }

  function forwardToFundingProgramFilterPage() {
    const url = new URL(window.location.origin + "/funding-programs");

    const selectedGrade =
      shortInfos[getIndexByIconName(ICON_GRADE_SELECTION, shortInfos)]
        .dropdownSelection?.selectedValue;
    const selectedGradeObject = preSelectionGrade.find(
      (grade) => grade.value === selectedGrade
    );

    const selectedFederalState =
      shortInfos[getIndexByIconName(ICON_FEDERAL_STATE_SELECTION, shortInfos)]
        .dropdownSelection?.selectedValue;
    const selectedFederalStateObject = preSelectionFederalState.find(
      (state) => state.value === selectedFederalState
    );

    if (selectedGradeObject) {
      url.searchParams.set("grade", selectedGradeObject.value.toString());
    }
    if (selectedFederalState && selectedFederalStateObject?.value) {
      url.searchParams.set("federalState", selectedFederalStateObject.value);
    }

    const selectedFundingTypes = [
      ...schulalltagPreSelections,
      ...furtherSupportingOptionsPreSelections,
    ].filter((preSelection) => preSelection.isSelected);

    if (selectedFundingTypes.length > 0 && selectedFundingTypes[0].key) {
      url.searchParams.set("fundingType", selectedFundingTypes[0].key);
    }

    const navigationUrl = "/funding-programs" + url.search;
    navigate(navigationUrl);
  }

  return (
    <Stack
      sx={{
        backgroundColor: LIGHT_BLUE,
        m: {
          sm: 2,
          md: 10,
        },
        display: "flex",
        padding: 2,
        borderRadius: 5,
      }}
    >
      <PreSelectionGroup
        title={"Für den Schulalltag"}
        preSelectionOptions={schulalltagPreSelections}
      />
      <PreSelectionGroup
        title={"Weitere Unterstützungsangebote"}
        preSelectionOptions={furtherSupportingOptionsPreSelections}
        color={BLACK}
      />
      <Box
        color={DARK_BLUE}
        sx={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          m: 6,
        }}
      >
        <Typography id={SHORT_INFOS_ELEMENT_ID} variant="h4">
          Noch zwei kurze Infos
        </Typography>
        <Typography variant="h6">
          Und schon findest du die passende Unterstützung
        </Typography>
      </Box>
      <PreSelectionGroup preSelectionOptions={shortInfos} />

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Button
          variant={"contained"}
          sx={{ width: "auto", borderRadius: 5, m: 4 }}
          onClick={() => forwardToFundingProgramFilterPage()}
        >
          <Typography variant="h6">Passende Unterstützung finden</Typography>
        </Button>
      </Box>
    </Stack>
  );
}
