import React, { ReactNode, useEffect } from "react";
import { useLogto } from "@logto/react";
import { useUser } from "../hooks/contextHooks";
import { User } from "../contexts/UserContext";
import { useLazyBackendRequest } from "../hooks/useLazyBackendRequest";

export default function AuthGuard(props: { children: ReactNode }) {
  const { children } = props;

  const { isAuthenticated, error, signOut } = useLogto();
  const { user, setUser } = useUser();

  const { request: fetchUser } = useLazyBackendRequest<User>(`user/me`);

  async function updateUserContext() {
    const response = await fetchUser();

    if (response.data != null) {
      setUser(response.data);
    } else {
      console.error("User data could not be loaded", response);
      await clearExpiredSessionData();
    }
  }

  /**
   * Necessary e.g. if the access token is invalid (e.g. because it expired)
   */
  async function clearExpiredSessionData() {
    try {
      setUser(null);
      await signOut(
        `${window.location.protocol}//${window.location.host}/callback`
      );
    } catch (error) {
      console.error("Error on clearing expired session data", error);
    }
  }

  useEffect(() => {
    if (isAuthenticated && user === null) {
      updateUserContext();
    }
  }, [isAuthenticated, setUser]);

  if (error) {
    console.error("Error on authentication", error);
  }

  return <>{children}</>;
}
