import * as React from "react";
import { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import AccountPopover from "../components/AccountPopover";
import Iconify from "../components/Iconify";
import { useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../hooks/contextHooks";
import { DARK_BLUE } from "../colors";
import {
  MAX_BLUE_LINEAR_GRADIENT,
  MAX_GREEN_LINEAR_GRADIENT,
} from "./PupilsPage";
import ChancenbuddyLogo from "../assets/chancenbuddy-logo-1-white-background.svg";
import { Link, Stack } from "@mui/material";

type Page = {
  title: string;
  forwardPath: string;
};

const publicPages: Page[] = [
  { title: "Für Schüler*Innen", forwardPath: "for-pupils" },
  { title: "Förderprogramme", forwardPath: "funding-programs" },
  { title: "Über Uns", forwardPath: "about-us" },
];

const pagesForAuthenticatedUsers: Page[] = [
  { title: "Organisationen", forwardPath: "organizations" },
];

const LOGO_TEXT_PLACEHOLDER = "Chancenbuddy";

function ResponsiveAppBar() {
  const navigate = useNavigate();
  const { isUserAuthenticated, user } = useUser();
  const location = useLocation();

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const [availablePages, setAvailablePages] = React.useState<Page[]>([]);

  const [isForPupilsPage, setIsForPupilsPage] = React.useState(true);

  useEffect(() => {
    if (isUserAuthenticated) {
      setAvailablePages([...publicPages, ...pagesForAuthenticatedUsers]);
    } else {
      setAvailablePages(publicPages);
    }
  }, [isUserAuthenticated, user]);

  useEffect(() => {
    setIsForPupilsPage(location.pathname.includes("for-pupils"));
  }, [location.pathname]);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  function navigateToPage(path: string) {
    navigate(`/${path}`);
    handleCloseNavMenu();
  }

  return (
    <AppBar
      position={isForPupilsPage ? "absolute" : "static"}
      sx={{
        ...(isForPupilsPage && {
          backgroundColor: "transparent",
          boxShadow: "none",
        }),
        ...(!isForPupilsPage && {
          backgroundImage: `linear-gradient(to right, ${MAX_BLUE_LINEAR_GRADIENT}, ${MAX_GREEN_LINEAR_GRADIENT})`,
        }),
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/*<AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} />*/}
          {/*  TODO spacing does not work as intended - Logo should be left, options in the middle, user icon at the righ*/}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Link href={"/"} color={"inherit"} underline="none">
              <Stack direction={"row"} alignItems={"center"}>
                <img
                  src={ChancenbuddyLogo}
                  alt="Chancenbuddy Logo"
                  style={{ height: "40px" }}
                />
                <Typography
                  variant="h6"
                  noWrap
                  sx={{
                    m: 2,
                    fontWeight: 700,
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  {LOGO_TEXT_PLACEHOLDER}
                </Typography>
              </Stack>
            </Link>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <Iconify icon={"ic:round-menu"} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {availablePages.map((page) => (
                  <MenuItem
                    key={page.title}
                    onClick={() => navigateToPage(page.forwardPath)}
                  >
                    <Typography textAlign="center">{page.title}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {availablePages.map((page) => (
                <Button
                  key={page.title}
                  onClick={() => navigateToPage(page.forwardPath)}
                  sx={{
                    my: 2,
                    color: "white",
                    display: "block",
                    border: location.pathname.includes(page.forwardPath)
                      ? "1px solid"
                      : "none",
                    "&:hover": {
                      color: DARK_BLUE,
                    },
                  }}
                >
                  {page.title}
                </Button>
              ))}
            </Box>

            <AccountPopover />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;
