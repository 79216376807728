import React from "react";
import "../App.css";
import {
  Organization_Insert_Input,
  OrganizationFragment,
  useGetOrganizationByIdQuery,
  useUpsertOrganizationProgramMutation,
} from "../generated/graphql";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../components/Loading";
import * as Yup from "yup";
import { Form, FormikErrors, FormikProvider, useFormik } from "formik";
import { enqueueSnackbar } from "notistack";
import { Box, Button, Card, Stack, TextField, Typography } from "@mui/material";
import {
  convertFormikValuesToOrganizationUpsertionInput,
  ErrorProps,
  getInitialValuesFromOrganization,
  OrganizationFormValues,
} from "../components/organization/EditOrganizationUtils";
import { OrganizationLogoPreview } from "../components/organization/OrganizationLogoPreview";

function AddOrEditOrganizationPage(props: { isEdit: boolean }) {
  const { isEdit } = props;

  const { organizationId } = useParams();

  const navigate = useNavigate();

  const {
    data,
    loading: loadingFundingProgram,
    error: errorLoadingOrganizationProgram,
  } = useGetOrganizationByIdQuery({
    variables: {
      id: organizationId ?? "",
    },
    fetchPolicy: "no-cache",
  });

  const organization: OrganizationFragment | null =
    data?.organization_by_pk ?? null;

  const [upsertOrganization, { error: errorUpsertingOrganization }] =
    useUpsertOrganizationProgramMutation();

  const FundingProgramSchema = Yup.object().shape({
    name: Yup.string().required("Name darf nicht leer bleiben"),
    logo_url: Yup.string().url("Muss eine gültige URL sein"),
    website_url: Yup.string().url("Muss eine gültige URL sein"),
  });

  const initialValues: OrganizationFormValues =
    getInitialValuesFromOrganization(organization);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: FundingProgramSchema,
    onSubmit: async (values, { resetForm, setErrors }) => {
      await updateOrganization(values, resetForm, setErrors);
    },
  });

  async function updateOrganization(
    values: OrganizationFormValues,
    resetForm: () => void,
    setErrors: (errors: FormikErrors<OrganizationFormValues>) => void
  ) {
    const updatedOrganization: Organization_Insert_Input =
      convertFormikValuesToOrganizationUpsertionInput(organization, values);

    try {
      await upsertOrganization({
        variables: {
          organization: updatedOrganization,
        },
      });

      resetForm();
      navigate("/organizations", { replace: true });
    } catch (error: any) {
      console.error(error);
      setErrors(error.message);
      enqueueSnackbar(
        !props.isEdit
          ? `Organisation konnte nicht erstellt werden: ${error.message}`
          : `Änderungen konnten nicht gespeichert werden: ${error.message}`,
        {
          variant: "error",
        }
      );
    }
  }

  const {
    errors,
    values,
    touched,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    getFieldProps,
  } = formik;

  function getErrorProps(
    name: keyof OrganizationFormValues,
    defaultText?: string
  ): ErrorProps {
    return {
      error: touched[name] && Boolean(errors[name]),
      helperText: touched[name] && String(errors[name] ?? defaultText ?? ""),
    };
  }

  if (loadingFundingProgram) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "40vh",
        }}
      >
        <Loading />
      </Box>
    );
  }

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete={"off"} onSubmit={handleSubmit}>
        <Card sx={{ p: 3 }}>
          <Stack spacing={3}>
            <Typography variant="h5">Organisations-Informationen</Typography>
            <TextField
              sx={{ flexShrink: 1 }}
              fullWidth
              label="Name"
              {...getFieldProps("name")}
              {...getErrorProps("name")}
              autoFocus
            />

            <TextField
              sx={{ flexShrink: 1 }}
              fullWidth
              label="Website Url"
              {...getFieldProps("website_url")}
              {...getErrorProps("website_url")}
              autoFocus
            />

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                sx={{ flexShrink: 1 }}
                fullWidth
                label="Logo Url"
                {...getFieldProps("logo_url")}
                {...getErrorProps("logo_url")}
                autoFocus
              />
              <OrganizationLogoPreview logo_url={values.logo_url} />
            </Box>
          </Stack>

          <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
            {isSubmitting && <Loading />}
            <Button
              type="submit"
              variant="contained"
              disabled={isSubmitting || Object.keys(errors).length > 0}
            >
              {!isEdit ? "Organisation hinzufügen" : "Änderungen speichern"}
            </Button>
          </Box>
        </Card>
      </Form>
    </FormikProvider>
  );
}

export default AddOrEditOrganizationPage;
