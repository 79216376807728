import React from "react";
import "./WaveSVG.css";
import {
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import Iconify from "../Iconify";
import { BLACK, DARK_GRAY, LIGHT_GREY, OFF_WHITE } from "../../colors";
import MenuItem from "@mui/material/MenuItem";

export type DropdownSelection = {
  selectionOptions?: {
    value: string | number;
    label: string;
  }[];
  selectedValue?: string | number;
  placeholder?: string;
};

export type PreSelection = {
  title: string;
  icon: string;

  key?: string; // should be available if no dropdown selection
  subtitle?: string; // should be available if no dropdown selection
  isSelected?: boolean; // should be available if no dropdown selection

  dropdownSelection?: DropdownSelection;
};

export const SHORT_INFOS_ELEMENT_ID = "short-infos";

export function PreSelection(props: {
  preSelection: PreSelection;
  color: string;
}) {
  const { preSelection, color } = props;

  const { title, subtitle, icon, dropdownSelection } = preSelection;

  const SHADOW_TO_THE_BOTTOM = 30;

  const [isSelected, setIsSelected] = React.useState(false);

  const [selectedValue, setSelectedValue] = React.useState<string | number>(
    dropdownSelection?.selectedValue ?? ""
  );

  function handleSelection(event: SelectChangeEvent<string | number>) {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    if (dropdownSelection) {
      dropdownSelection.selectedValue = newValue;
    }

    handlePreSelection(false);
  }

  function handlePreSelection(scrollToNextSection: boolean) {
    preSelection.isSelected = !preSelection.isSelected;
    setIsSelected(preSelection.isSelected);

    if (preSelection.isSelected && scrollToNextSection) {
      findAndScrollToNextSection();
    }
  }

  function findAndScrollToNextSection() {
    const element = document.getElementById(SHORT_INFOS_ELEMENT_ID);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  const isCardClickable = !preSelection.dropdownSelection;

  return (
    <Stack
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: isSelected && isCardClickable ? OFF_WHITE : "white",
        padding: 4,
        margin: 2,
        borderRadius: 3,
        color: color,
        textAlign: "center",
        cursor: isCardClickable ? "pointer" : "default",
        "&:hover": isCardClickable
          ? {
              transition: "box-shadow 0.3s linear",
              boxShadow: `0 20px ${SHADOW_TO_THE_BOTTOM}px -20px ${DARK_GRAY}`,
            }
          : {},
      }}
      onClick={() => handlePreSelection(true)}
    >
      <Iconify icon={icon} fontSize={60} />
      <Typography variant={"h6"}>{title}</Typography>
      {subtitle && (
        <Typography variant={"subtitle1"} color={LIGHT_GREY} marginTop={2}>
          {subtitle}
        </Typography>
      )}
      {dropdownSelection && (
        <FormControl sx={{ m: 2, minWidth: 180 }}>
          <InputLabel sx={{ color: BLACK }}>
            {dropdownSelection.placeholder}
          </InputLabel>
          <Select
            value={selectedValue}
            label={dropdownSelection.placeholder}
            onChange={(event) => handleSelection(event)}
            variant={"outlined"}
          >
            {dropdownSelection.selectionOptions?.map((selectionOption) => (
              <MenuItem
                value={selectionOption.value}
                key={selectionOption.value}
              >
                {selectionOption.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Stack>
  );
}
