import React, { MouseEvent, useState } from "react";
import {
  Box,
  Divider,
  MenuItem,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import { useLogto } from "@logto/react";
import { useUser } from "../hooks/contextHooks";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { UserProfileImage } from "./UserProfileImage";
import packageJson from "../../package.json";

type Setting = {
  name: string;
  onClick: () => void;
};

export default function AccountPopover() {
  const { signOut } = useLogto();
  const { user, firstName, lastName, isUserAuthenticated } = useUser();

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const appVersion = packageJson.version;

  const settings: Setting[] = [
    { name: "Logout", onClick: handleLogoutClicked },
  ];

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  async function handleLogoutClicked() {
    await signOut(`${window.location.protocol}//${window.location.host}`);
  }

  if (!isUserAuthenticated) {
    return <></>;
  }

  return (
    <Box sx={{ display: "flex", maxWidth: "400px" }}>
      <Box sx={{ flexGrow: 1 }}>
        <Tooltip title="Einstellungen öffnen">
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <UserProfileImage firstName={firstName} lastName={lastName} />
          </IconButton>
        </Tooltip>
      </Box>

      <Popover
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        anchorEl={anchorElUser}
        keepMounted
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          width: "max-content",
          minWidth: "220px",
          mt: "45px",
          p: 1,
        }}
        PaperProps={{
          style: { maxWidth: "100%" },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5, width: "max-content" }}>
          <Typography variant="subtitle1" noWrap>
            {user?.name}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {user?.email ?? "Keine E-Mail-Adresse festgelegt"}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "text.secondary", mt: 1 }}
            noWrap
          >
            Version {appVersion}
          </Typography>
        </Box>
        <Divider />

        <Stack spacing={0.5}>
          {settings.map((setting: Setting) => (
            <MenuItem
              key={setting.name}
              onClick={() => {
                handleCloseUserMenu();
                setting.onClick();
              }}
            >
              <Typography textAlign="center">{setting.name}</Typography>
            </MenuItem>
          ))}
        </Stack>
      </Popover>
    </Box>
  );
}
