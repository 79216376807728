export function isProduction(): boolean {
  const endpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT;
  if (!endpoint) {
    console.error("REACT_APP_GRAPHQL_ENDPOINT is not set");
    return true; // fail-safe
  }
  return !endpoint.includes("localhost");
}

export function getEnumKeyFromValue(Enum: any, value: any): string | undefined {
  return Object.keys(Enum).find((key) => Enum[key] === value);
}
