import { alpha } from "@mui/material";

export const BLUE = "#009EF7";
export const BLUE_10 = alpha(BLUE, 0.1);
export const BLUE_20 = alpha(BLUE, 0.2);
export const ORANGE = "#FCBF54";
export const YELLOW = "#F5D67A";
export const GREEN = "#C1E5AE";
export const RED = "#EA6251";
export const DARK_GREEN = "#00AB55";
export const DARK_GREEN_40 = alpha(DARK_GREEN, 0.4);
export const PURPLE = alpha("#533DA6", 0.6);
export const LIGHT_PURPLE = alpha("#86348C", 0.6);
export const TURQUOISE = alpha("#6ECED4", 0.6);

export const GRAY = "#F4F6F8";
export const DARK_GRAY = "#bcbec2";

export const LIGHT_GREY = "#B1B1B1";
export const DARK_BLUE = "#0074A6";
export const LIGHT_BLUE = "#EEF8FE";
export const BLACK = "#323232";
export const OFF_WHITE = "#FAF9F6";
