import { useNavigate } from "react-router-dom";
import { useHandleSignInCallback } from "@logto/react";
import { CircularProgress, Typography } from "@mui/material";

export const LoginCallback = () => {
  const navigate = useNavigate();

  const { isLoading } = useHandleSignInCallback(() => {
    navigate("/", { replace: true });
  });

  if (isLoading) {
    return <CircularProgress />;
  }

  return <Typography>Weiterleitung zur Anmeldung ...</Typography>;
};
