import { useState } from "react";
import { useLogto } from "@logto/react";
import { LOGTO_SERVER_RESSOURCE_URL, SERVER_URL } from "../index";
import { merge } from "lodash";

export const useLazyBackendRequest = <SerializedData>(
  path: string,
  lazyRequest?: SerializedRequest<any>
): LazyState<SerializedData> => {
  const [data, setData] = useState<SerializedData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);
  const { getAccessToken } = useLogto();

  const makeRequest: SerializedRequestHandler<SerializedData> = async (
    serializedRequest?: SerializedRequest<any>
  ): Promise<SerializedResponse<SerializedData>> => {
    setLoading(true);
    const accessToken = await getAccessToken(LOGTO_SERVER_RESSOURCE_URL);
    const baseOptions = getBaseRequestOptions(accessToken);

    const optionsWithAuth =
      serializedRequest != null
        ? merge(serializedRequest, baseOptions, lazyRequest)
        : merge(baseOptions, lazyRequest);

    const response = await fetch(composeBackendUrl(path), optionsWithAuth);

    try {
      const data = await response.json();
      if (response.ok) {
        setData(data);
        return { data };
      } else {
        setError(response);
        return getErrorObjectFromResponse(response, data);
      }
    } catch (error) {
      setError(error);
      return getErrorObjectFromResponse(response, error);
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    request: (request) => makeRequest(request),
    loading,
    error,
  };
};

function getBaseRequestOptions(accessToken?: string) {
  if (!accessToken) {
    console.error(`Access could not be retrieved: ${accessToken}`);
  }
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
}

function getErrorObjectFromResponse(
  response: Response,
  data: any
): { error: RequestError } {
  return {
    error: getErrorFromResponse(response, data),
  };
}

function getErrorFromResponse(response: Response, data: any): RequestError {
  return {
    status: response.status,
    statusText: response.statusText,
    data: data,
  };
}

function composeBackendUrl(path: string) {
  const parsedPath = path.startsWith("/") ? path : `/${path}`;
  return SERVER_URL + parsedPath;
}

export interface BaseState<SerializedData> {
  data: SerializedData | null;
  loading: boolean;
  error: any;
}

export interface RequestError {
  status: number;
  statusText: string;
  data: any;
}

export interface SerializedResponse<SerializedData> {
  data?: SerializedData;
  error?: RequestError;
}

export type SerializedRequestHandler<SerializedData> = (
  request?: SerializedRequest<any>
) => Promise<SerializedResponse<SerializedData>>;

export interface LazyState<SerializedData> extends BaseState<SerializedData> {
  request: SerializedRequestHandler<SerializedData>;
}

export interface SerializedRequest<Body> {
  body?: Body;
  method?: "GET" | "POST" | "PUT" | "DELETE";
}
