import React from "react";
import "./WaveSVG.css";
import { DARK_BLUE } from "../../colors";
import { DividerWithText } from "../shared/DividerWithText";
import { PreSelection } from "./PreSelection";
import { Grid } from "@mui/material";

export function PreSelectionGroup(props: {
  title?: string;
  preSelectionOptions: PreSelection[];
  color?: string;
}) {
  const { preSelectionOptions, title, color = DARK_BLUE } = props;

  return (
    <>
      {title && <DividerWithText text={title} color={color} />}

      <Grid container justifyContent="center" alignItems="center">
        {preSelectionOptions.map((preSelection) => (
          <Grid item xs={12} sm={4} key={preSelection.title}>
            <PreSelection preSelection={preSelection} color={color} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
