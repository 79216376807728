import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { LogtoConfig, LogtoProvider } from "@logto/react";
import { UserContextProvider } from "./contexts/UserContext";
import { BrowserRouter } from "react-router-dom";
import LogtoApolloProvider from "./contexts/LogtoApolloProvider";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { SnackbarProvider } from "notistack";
import { createTheme, ThemeProvider } from "@mui/material";
import { DARK_BLUE } from "./colors";

export const LOGTO_URL = "https://logto.chancenbuddy.de";
export const LOGTO_CLIENT_APP_ID = "igci4ws62d5lwee1v0fwa";

export const PRODUCTION_HASURA_URL = "https://hasura.chancenbuddy.de";

export const LOGTO_SERVER_RESSOURCE_URL = "https://server.chancenbuddy.de";
export const SERVER_URL =
  process.env.REACT_APP_SERVER_URL ?? LOGTO_SERVER_RESSOURCE_URL;

export const GRAPHQL_ENDPOINT = "https://hasura.chancenbuddy.de/v1/graphql";

export const GRAPHQL_WS_ENDPOINT = "wss://hasura.chancenbuddy.de/v1/graphql";

// TODO use env files
const logtoConfig: LogtoConfig = {
  endpoint: LOGTO_URL,
  appId: LOGTO_CLIENT_APP_ID,
  resources: [PRODUCTION_HASURA_URL, LOGTO_SERVER_RESSOURCE_URL],
};

const theme = createTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
    fontSize: 12,
  },
  palette: {
    primary: {
      main: DARK_BLUE,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <LogtoProvider config={logtoConfig}>
      <UserContextProvider>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            maxSnack={3}
            preventDuplicate
            autoHideDuration={5000}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
          >
            <BrowserRouter>
              <LogtoApolloProvider>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <App />
                </LocalizationProvider>
              </LogtoApolloProvider>
            </BrowserRouter>
          </SnackbarProvider>
        </ThemeProvider>
      </UserContextProvider>
    </LogtoProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
