import React from "react";
import Typography from "@mui/material/Typography";

export function TextUppercaseAndSpacing(props: { displayedText: string }) {
  return (
    <Typography sx={{ textTransform: "uppercase", letterSpacing: "0.5rem" }}>
      {props.displayedText}
    </Typography>
  );
}
