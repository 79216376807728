import { createContext, ReactNode, useEffect, useState } from "react";
import { getExpectedRole } from "./LogtoApolloProvider";
import { getOrDefineAnonymousUserId } from "../shared/analysis.utils";

export interface User {
  id: string;
  email: string;
  name: string;
  username: string;
  roles: UserRole[];
}

export type UserRole = "user" | "content_creator" | "not_authenticated_user";

export const USER_ROLE_PRIORITY = [
  "content_creator",
  "user",
  "not_authenticated_user",
];

interface UserContextProps {
  user: User | null;
  setUser: (user: User | null) => void;
  role: UserRole | null;
  firstName: string;
  lastName: string;
  isUserAuthenticated: boolean;
  anonymousUserId: string | null;
  setAnonymousUserId: (id: string | null) => void;
}

export const UserContext = createContext<UserContextProps>({
  user: null,
  setUser: () => {},
  role: null,
  firstName: "",
  lastName: "",
  isUserAuthenticated: false,
  anonymousUserId: null,
  setAnonymousUserId: () => {},
});

export function UserContextProvider(props: { children: ReactNode }) {
  const { children } = props;
  const [user, setUser] = useState<User | null>(null);
  const roles: UserRole[] = null ? user?.roles ?? [] : ["user"];
  const expectedRole = user == null ? null : getExpectedRole(roles);

  const [isUserAuthenticated, setIsUserAuthenticated] =
    useState<boolean>(false);

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");

  const [anonymousUserId, setAnonymousUserId] = useState<string | null>(null);

  useEffect(function setAnonymousUserIdIfNotDefined() {
    setAnonymousUserId(getOrDefineAnonymousUserId());
  }, []);

  useEffect(() => {
    setIsUserAuthenticated(user !== null);
  }, [user]);

  useEffect(
    function updateFirstAndLastName() {
      let updatedFirstName = user?.name?.split(" ")[0] || "";
      const updatedLastName = user?.name?.split(" ")[1] || "";

      if (updatedFirstName === "" && updatedLastName === "") {
        updatedFirstName = "-";
      }

      setFirstName(updatedFirstName);
      setLastName(updatedLastName);
    },
    [user?.name]
  );

  return (
    <UserContext.Provider
      value={{
        user: user,
        setUser,
        role: expectedRole,
        firstName,
        lastName,
        isUserAuthenticated,
        anonymousUserId,
        setAnonymousUserId,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
