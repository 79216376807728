import Box from "@mui/material/Box";

function DataProtectionDeclarationPage() {
  return (
    <Box m={"2rem"}>
      <h1>Datenschutzerklärung</h1>
      <h2 id="m716">Präambel</h2>
      <p>
        Mit der folgenden Datenschutzerklärung möchten wir Sie darüber
        aufklären, welche Arten Ihrer personenbezogenen Daten (nachfolgend auch
        kurz als "Daten" bezeichnet) wir zu welchen Zwecken und in welchem
        Umfang verarbeiten. Die Datenschutzerklärung gilt für alle von uns
        durchgeführten Verarbeitungen personenbezogener Daten, sowohl im Rahmen
        der Erbringung unserer Leistungen als auch insbesondere auf unseren
        Webseiten, in mobilen Applikationen sowie innerhalb externer
        Onlinepräsenzen, wie z. B. unserer Social-Media-Profile (nachfolgend
        zusammenfassend bezeichnet als "Onlineangebot").
      </p>
      <p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>
      <p>Stand: 8. April 2024</p>
      <h2>Inhaltsübersicht</h2>
      <ul className="index">
        <li>
          <a className="index-link" href="#m716">
            Präambel
          </a>
        </li>
        <li>
          <a className="index-link" href="#m3">
            Verantwortlicher
          </a>
        </li>
        <li>
          <a className="index-link" href="#mOverview">
            Übersicht der Verarbeitungen
          </a>
        </li>
        <li>
          <a className="index-link" href="#m2427">
            Maßgebliche Rechtsgrundlagen
          </a>
        </li>
        <li>
          <a className="index-link" href="#m12">
            Löschung von Daten
          </a>
        </li>
        <li>
          <a className="index-link" href="#m10">
            Rechte der betroffenen Personen
          </a>
        </li>
        <li>
          <a className="index-link" href="#m354">
            Wahrnehmung von Aufgaben nach Satzung oder Geschäftsordnung
          </a>
        </li>
        <li>
          <a className="index-link" href="#m225">
            Bereitstellung des Onlineangebots und Webhosting
          </a>
        </li>
        <li>
          <a className="index-link" href="#m638">
            Werbliche Kommunikation via E-Mail, Post, Fax oder Telefon
          </a>
        </li>
        <li>
          <a className="index-link" href="#m136">
            Präsenzen in sozialen Netzwerken (Social Media)
          </a>
        </li>
      </ul>
      <h2 id="m3">Verantwortlicher</h2>
      <p>
        Chancenbuddy gUG
        <br />
        vertreten durch Yannick Folsche & Clara Bleckmann
        <br />
        Löhergraben 6<br />
        52064 Aachen
        <br />
        <br />
        Registergericht: Amtsgericht Aachen
        <br />
        Registernummer: HRB 25478
      </p>
      E-Mail-Adresse:{" "}
      <a href="mailto:info@chancenbuddy.de">info@chancenbuddy.de</a>
      <h2 id="mOverview">Übersicht der Verarbeitungen</h2>
      <p>
        Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und
        die Zwecke ihrer Verarbeitung zusammen und verweist auf die betroffenen
        Personen.
      </p>
      <h3>Arten der verarbeiteten Daten</h3>
      <ul>
        <li>Bestandsdaten.</li>
        <li>Zahlungsdaten.</li>
        <li>Kontaktdaten.</li>
        <li>Inhaltsdaten.</li>
        <li>Vertragsdaten.</li>
        <li>Nutzungsdaten.</li>
        <li>Meta-, Kommunikations- und Verfahrensdaten.</li>
      </ul>
      <h3>Kategorien betroffener Personen</h3>
      <ul>
        <li>Kommunikationspartner.</li>
        <li>Nutzer.</li>
        <li>Mitglieder.</li>
        <li>Geschäfts- und Vertragspartner.</li>
      </ul>
      <h3>Zwecke der Verarbeitung</h3>
      <ul>
        <li>
          Erbringung vertraglicher Leistungen und Erfüllung vertraglicher
          Pflichten.
        </li>
        <li>Kontaktanfragen und Kommunikation.</li>
        <li>Sicherheitsmaßnahmen.</li>
        <li>Direktmarketing.</li>
        <li>Verwaltung und Beantwortung von Anfragen.</li>
        <li>Feedback.</li>
        <li>Marketing.</li>
        <li>
          Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.
        </li>
        <li>Informationstechnische Infrastruktur.</li>
      </ul>
      <h2 id="m2427">Maßgebliche Rechtsgrundlagen</h2>
      <p>
        <strong>Maßgebliche Rechtsgrundlagen nach der DSGVO: </strong>Im
        Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der DSGVO,
        auf deren Basis wir personenbezogene Daten verarbeiten. Bitte nehmen Sie
        zur Kenntnis, dass neben den Regelungen der DSGVO nationale
        Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder Sitzland gelten
        können. Sollten ferner im Einzelfall speziellere Rechtsgrundlagen
        maßgeblich sein, teilen wir Ihnen diese in der Datenschutzerklärung mit.
      </p>
      <ul>
        <li>
          <strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO)</strong> - Die
          betroffene Person hat ihre Einwilligung in die Verarbeitung der sie
          betreffenden personenbezogenen Daten für einen spezifischen Zweck oder
          mehrere bestimmte Zwecke gegeben.
        </li>
        <li>
          <strong>
            Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1
            lit. b) DSGVO)
          </strong>{" "}
          - Die Verarbeitung ist für die Erfüllung eines Vertrags, dessen
          Vertragspartei die betroffene Person ist, oder zur Durchführung
          vorvertraglicher Maßnahmen erforderlich, die auf Anfrage der
          betroffenen Person erfolgen.
        </li>
        <li>
          <strong>
            Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)
          </strong>{" "}
          - die Verarbeitung ist zur Wahrung der berechtigten Interessen des
          Verantwortlichen oder eines Dritten notwendig, vorausgesetzt, dass die
          Interessen, Grundrechte und Grundfreiheiten der betroffenen Person,
          die den Schutz personenbezogener Daten verlangen, nicht überwiegen.
        </li>
      </ul>
      <p>
        <strong>Nationale Datenschutzregelungen in Deutschland: </strong>
        Zusätzlich zu den Datenschutzregelungen der DSGVO gelten nationale
        Regelungen zum Datenschutz in Deutschland. Hierzu gehört insbesondere
        das Gesetz zum Schutz vor Missbrauch personenbezogener Daten bei der
        Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält
        insbesondere Spezialregelungen zum Recht auf Auskunft, zum Recht auf
        Löschung, zum Widerspruchsrecht, zur Verarbeitung besonderer Kategorien
        personenbezogener Daten, zur Verarbeitung für andere Zwecke und zur
        Übermittlung sowie automatisierten Entscheidungsfindung im Einzelfall
        einschließlich Profiling. Ferner können Landesdatenschutzgesetze der
        einzelnen Bundesländer zur Anwendung gelangen.
      </p>
      <h2 id="m12">Löschung von Daten</h2>
      <p>
        Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen
        Vorgaben gelöscht, sobald deren zur Verarbeitung erlaubten
        Einwilligungen widerrufen werden oder sonstige Erlaubnisse entfallen (z.
        B. wenn der Zweck der Verarbeitung dieser Daten entfallen ist oder sie
        für den Zweck nicht erforderlich sind). Sofern die Daten nicht gelöscht
        werden, weil sie für andere und gesetzlich zulässige Zwecke erforderlich
        sind, wird deren Verarbeitung auf diese Zwecke beschränkt. D. h., die
        Daten werden gesperrt und nicht für andere Zwecke verarbeitet. Das gilt
        z. B. für Daten, die aus handels- oder steuerrechtlichen Gründen
        aufbewahrt werden müssen oder deren Speicherung zur Geltendmachung,
        Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der
        Rechte einer anderen natürlichen oder juristischen Person erforderlich
        ist. Unsere Datenschutzhinweise können ferner weitere Angaben zu der
        Aufbewahrung und Löschung von Daten beinhalten, die für die jeweiligen
        Verarbeitungen vorrangig gelten.
      </p>
      <h2 id="m10">Rechte der betroffenen Personen</h2>
      <p>
        Rechte der betroffenen Personen aus der DSGVO: Ihnen stehen als
        Betroffene nach der DSGVO verschiedene Rechte zu, die sich insbesondere
        aus Art. 15 bis 21 DSGVO ergeben:
      </p>
      <ul>
        <li>
          <strong>
            Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus
            Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung
            der Sie betreffenden personenbezogenen Daten, die aufgrund von Art.
            6 Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies
            gilt auch für ein auf diese Bestimmungen gestütztes Profiling.
            Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um
            Direktwerbung zu betreiben, haben Sie das Recht, jederzeit
            Widerspruch gegen die Verarbeitung der Sie betreffenden
            personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen;
            dies gilt auch für das Profiling, soweit es mit solcher
            Direktwerbung in Verbindung steht.
          </strong>
        </li>
        <li>
          <strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das
          Recht, erteilte Einwilligungen jederzeit zu widerrufen.
        </li>
        <li>
          <strong>Auskunftsrecht:</strong> Sie haben das Recht, eine Bestätigung
          darüber zu verlangen, ob betreffende Daten verarbeitet werden und auf
          Auskunft über diese Daten sowie auf weitere Informationen und Kopie
          der Daten entsprechend den gesetzlichen Vorgaben.
        </li>
        <li>
          <strong>Recht auf Berichtigung:</strong> Sie haben entsprechend den
          gesetzlichen Vorgaben das Recht, die Vervollständigung der Sie
          betreffenden Daten oder die Berichtigung der Sie betreffenden
          unrichtigen Daten zu verlangen.
        </li>
        <li>
          <strong>
            Recht auf Löschung und Einschränkung der Verarbeitung:
          </strong>{" "}
          Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht, zu
          verlangen, dass Sie betreffende Daten unverzüglich gelöscht werden,
          bzw. alternativ nach Maßgabe der gesetzlichen Vorgaben eine
          Einschränkung der Verarbeitung der Daten zu verlangen.
        </li>
        <li>
          <strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das Recht,
          Sie betreffende Daten, die Sie uns bereitgestellt haben, nach Maßgabe
          der gesetzlichen Vorgaben in einem strukturierten, gängigen und
          maschinenlesbaren Format zu erhalten oder deren Übermittlung an einen
          anderen Verantwortlichen zu fordern.
        </li>
        <li>
          <strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie haben
          unbeschadet eines anderweitigen verwaltungsrechtlichen oder
          gerichtlichen Rechtsbehelfs das Recht auf Beschwerde bei einer
          Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen
          Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen
          Verstoßes, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie
          betreffenden personenbezogenen Daten gegen die Vorgaben der DSGVO
          verstößt.
        </li>
      </ul>
      <h2 id="m354">
        Wahrnehmung von Aufgaben nach Satzung oder Geschäftsordnung
      </h2>
      <p>
        Wir verarbeiten die Daten unserer Mitglieder, Unterstützer,
        Interessenten, Geschäftspartner oder sonstiger Personen (Zusammenfassend
        "Betroffene"), wenn wir mit ihnen in einem Mitgliedschafts- oder
        sonstigem geschäftlichen Verhältnis stehen und unsere Aufgaben
        wahrnehmen sowie Empfänger von Leistungen und Zuwendungen sind. Im
        Übrigen verarbeiten wir die Daten Betroffener auf Grundlage unserer
        berechtigten Interessen, z. B. wenn es sich um administrative Aufgaben
        oder Öffentlichkeitsarbeit handelt.
      </p>
      <p>
        Die hierbei verarbeiteten Daten, die Art, der Umfang und der Zweck und
        die Erforderlichkeit ihrer Verarbeitung, bestimmen sich nach dem
        zugrundeliegenden Mitgliedschafts- oder Vertragsverhältnis, aus dem sich
        auch die Erforderlichkeit etwaiger Datenangaben ergeben (im Übrigen
        weisen wir auf erforderliche Daten hin).
      </p>
      <p>
        Wir löschen Daten, die zur Erbringung unserer satzungs- und
        geschäftsmäßigen Zwecke nicht mehr erforderlich sind. Dies bestimmt sich
        entsprechend der jeweiligen Aufgaben und vertraglichen Beziehungen. Wir
        bewahren die Daten so lange auf, wie sie zur Geschäftsabwicklung, als
        auch im Hinblick auf etwaige Gewährleistungs- oder Haftungspflichten auf
        Grundlage unserer berechtigten Interesse an deren Regelung relevant sein
        können. Die Erforderlichkeit der Aufbewahrung der Daten wird regelmäßig
        überprüft; im Übrigen gelten die gesetzlichen Aufbewahrungspflichten.
      </p>
      <ul className="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z. B. der
          vollständige Name, Wohnadresse, Kontaktinformationen, Kundennummer,
          etc.); Zahlungsdaten (z. B. Bankverbindungen, Rechnungen,
          Zahlungshistorie); Kontaktdaten (z. B. Post- und E-Mail-Adressen oder
          Telefonnummern). Vertragsdaten (z. B. Vertragsgegenstand, Laufzeit,
          Kundenkategorie).
        </li>
        <li>
          <strong>Betroffene Personen:</strong> Nutzer (z. B. Webseitenbesucher,
          Nutzer von Onlinediensten); Mitglieder. Geschäfts- und
          Vertragspartner.
        </li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher
          Leistungen und Erfüllung vertraglicher Pflichten; Kontaktanfragen und
          Kommunikation. Verwaltung und Beantwortung von Anfragen.
        </li>
        <li className="">
          <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
          vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
          Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
        </li>
      </ul>
      <h2 id="m225">Bereitstellung des Onlineangebots und Webhosting</h2>
      <p>
        Wir verarbeiten die Daten der Nutzer, um ihnen unsere Online-Dienste zur
        Verfügung stellen zu können. Zu diesem Zweck verarbeiten wir die
        IP-Adresse des Nutzers, die notwendig ist, um die Inhalte und Funktionen
        unserer Online-Dienste an den Browser oder das Endgerät der Nutzer zu
        übermitteln.
      </p>
      <ul className="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z. B.
          Seitenaufrufe und Verweildauer, Klickpfade, Nutzungsintensität und
          -frequenz, verwendete Gerätetypen und Betriebssysteme, Interaktionen
          mit Inhalten und Funktionen). Meta-, Kommunikations- und
          Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
          Identifikationsnummern, beteiligte Personen).
        </li>
        <li>
          <strong>Betroffene Personen:</strong> Nutzer (z. B. Webseitenbesucher,
          Nutzer von Onlinediensten).
        </li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres
          Onlineangebotes und Nutzerfreundlichkeit; Informationstechnische
          Infrastruktur (Betrieb und Bereitstellung von Informationssystemen und
          technischen Geräten (Computer, Server etc.).). Sicherheitsmaßnahmen.
        </li>
        <li className="">
          <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs.
          1 S. 1 lit. f) DSGVO).
        </li>
      </ul>
      <p>
        <strong>
          Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
        </strong>
      </p>
      <ul className="m-elements">
        <li>
          <strong>Erhebung von Zugriffsdaten und Logfiles: </strong>Der Zugriff
          auf unser Onlineangebot wird in Form von so genannten
          "Server-Logfiles" protokolliert. Zu den Serverlogfiles können die
          Adresse und Name der abgerufenen Webseiten und Dateien, Datum und
          Uhrzeit des Abrufs, übertragene Datenmengen, Meldung über
          erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem des
          Nutzers, Referrer URL (die zuvor besuchte Seite) und im Regelfall
          IP-Adressen und der anfragende Provider gehören. Die Serverlogfiles
          können zum einen zu Zwecken der Sicherheit eingesetzt werden, z. B.,
          um eine Überlastung der Server zu vermeiden (insbesondere im Fall von
          missbräuchlichen Angriffen, sogenannten DDoS-Attacken) und zum
          anderen, um die Auslastung der Server und ihre Stabilität
          sicherzustellen;{" "}
          <span className="">
            <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
            Abs. 1 S. 1 lit. f) DSGVO).{" "}
          </span>
          <strong>Löschung von Daten:</strong> Logfile-Informationen werden für
          die Dauer von maximal 30 Tagen gespeichert und danach gelöscht oder
          anonymisiert. Daten, deren weitere Aufbewahrung zu Beweiszwecken
          erforderlich ist, sind bis zur endgültigen Klärung des jeweiligen
          Vorfalls von der Löschung ausgenommen.
        </li>
        <li>
          <strong>Hetzner: </strong>Leistungen auf dem Gebiet der Bereitstellung
          von informationstechnischer Infrastruktur und verbundenen
          Dienstleistungen (z. B. Speicherplatz und/oder Rechenkapazitäten);{" "}
          <strong>Dienstanbieter:</strong> Hetzner Online GmbH, Industriestr.
          25, 91710 Gunzenhausen, Deutschland;{" "}
          <span className="">
            <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
            Abs. 1 S. 1 lit. f) DSGVO);{" "}
          </span>
          <strong>Website:</strong>
          <a href="https://www.hetzner.com" target="_blank">
            https://www.hetzner.com
          </a>
          ; <strong>Datenschutzerklärung:</strong>{" "}
          <a
            href="https://www.hetzner.com/de/rechtliches/datenschutz"
            target="_blank"
          >
            https://www.hetzner.com/de/rechtliches/datenschutz
          </a>
          . <strong>Auftragsverarbeitungsvertrag:</strong>
          <a
            href="https://docs.hetzner.com/de/general/general-terms-and-conditions/data-privacy-faq/"
            target="_blank"
          >
            https://docs.hetzner.com/de/general/general-terms-and-conditions/data-privacy-faq/
          </a>
          .
        </li>
      </ul>
      <h2 id="m638">
        Werbliche Kommunikation via E-Mail, Post, Fax oder Telefon
      </h2>
      <p>
        Wir verarbeiten personenbezogene Daten zu Zwecken der werblichen
        Kommunikation, die über diverse Kanäle, wie z. B. E-Mail, Telefon, Post
        oder Fax, entsprechend den gesetzlichen Vorgaben erfolgen kann.
      </p>
      <p>
        Die Empfänger haben das Recht, erteilte Einwilligungen jederzeit zu
        widerrufen oder der werblichen Kommunikation jederzeit zu widersprechen.
      </p>
      <p>
        Nach Widerruf oder Widerspruch speichern wir die zum Nachweis der
        bisherigen Berechtigung erforderlichen Daten zur Kontaktaufnahme oder
        Zusendung bis zu drei Jahre nach Ablauf des Jahres des Widerrufs oder
        Widerspruchs auf der Grundlage unserer berechtigten Interessen. Die
        Verarbeitung dieser Daten ist auf den Zweck einer möglichen Abwehr von
        Ansprüchen beschränkt. Auf der Grundlage des berechtigten Interesses,
        den Widerruf bzw. Widerspruch der Nutzer dauerhaft zu beachten,
        speichern wir ferner die zur Vermeidung einer erneuten Kontaktaufnahme
        erforderlichen Daten (z. B. je nach Kommunikationskanal die
        E-Mail-Adresse, Telefonnummer, Name).
      </p>
      <ul className="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z. B. der
          vollständige Name, Wohnadresse, Kontaktinformationen, Kundennummer,
          etc.). Kontaktdaten (z. B. Post- und E-Mail-Adressen oder
          Telefonnummern).
        </li>
        <li>
          <strong>Betroffene Personen:</strong> Kommunikationspartner.
        </li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Direktmarketing (z. B. per
          E-Mail oder postalisch).
        </li>
        <li className="">
          <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1
          lit. a) DSGVO). Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f)
          DSGVO).
        </li>
      </ul>
      <h2 id="m136">Präsenzen in sozialen Netzwerken (Social Media)</h2>
      <p>
        Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und
        verarbeiten in diesem Rahmen Nutzerdaten, um mit den dort aktiven
        Nutzern zu kommunizieren oder Informationen über uns anzubieten.
      </p>
      <p>
        Wir weisen darauf hin, dass dabei Nutzerdaten außerhalb des Raumes der
        Europäischen Union verarbeitet werden können. Hierdurch können sich für
        die Nutzer Risiken ergeben, weil so zum Beispiel die Durchsetzung der
        Nutzerrechte erschwert werden könnte.
      </p>
      <p>
        Ferner werden die Daten der Nutzer innerhalb sozialer Netzwerke im
        Regelfall für Marktforschungs- und Werbezwecke verarbeitet. So können
        beispielsweise anhand des Nutzungsverhaltens und sich daraus ergebender
        Interessen der Nutzer Nutzungsprofile erstellt werden. Letztere finden
        möglicherweise wiederum Verwendung, um etwa Werbeanzeigen innerhalb und
        außerhalb der Netzwerke zu schalten, die mutmaßlich den Interessen der
        Nutzer entsprechen. Daher werden im Regelfall Cookies auf den Rechnern
        der Nutzer gespeichert, in denen das Nutzungsverhalten und die
        Interessen der Nutzer gespeichert werden. Zudem können in den
        Nutzungsprofilen auch Daten unabhängig der von den Nutzern verwendeten
        Geräten gespeichert werden (insbesondere, wenn sie Mitglieder der
        jeweiligen Plattformen und dort eingeloggt sind).
      </p>
      <p>
        Für eine detaillierte Darstellung der jeweiligen Verarbeitungsformen und
        der Widerspruchsmöglichkeiten (Opt-out) verweisen wir auf die
        Datenschutzerklärungen und Angaben der Betreiber der jeweiligen
        Netzwerke.
      </p>
      <p>
        Auch im Fall von Auskunftsanfragen und der Geltendmachung von
        Betroffenenrechten weisen wir darauf hin, dass diese am effektivsten bei
        den Anbietern geltend gemacht werden können. Nur Letztere haben jeweils
        Zugriff auf die Nutzerdaten und können direkt entsprechende Maßnahmen
        ergreifen und Auskünfte geben. Sollten Sie dennoch Hilfe benötigen, dann
        können Sie sich an uns wenden.
      </p>
      <ul className="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z. B. Post-
          und E-Mail-Adressen oder Telefonnummern); Inhaltsdaten (z. B.
          textliche oder bildliche Nachrichten und Beiträge sowie die sie
          betreffenden Informationen, wie z. B. Angaben zur Autorenschaft oder
          Zeitpunkt der Erstellung); Nutzungsdaten (z. B. Seitenaufrufe und
          Verweildauer, Klickpfade, Nutzungsintensität und -frequenz, verwendete
          Gerätetypen und Betriebssysteme, Interaktionen mit Inhalten und
          Funktionen). Meta-, Kommunikations- und Verfahrensdaten (z. B.
          IP-Adressen, Zeitangaben, Identifikationsnummern, beteiligte
          Personen).
        </li>
        <li>
          <strong>Betroffene Personen:</strong> Nutzer (z. B. Webseitenbesucher,
          Nutzer von Onlinediensten).
        </li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und
          Kommunikation; Feedback (z. B. Sammeln von Feedback via
          Online-Formular). Marketing.
        </li>
        <li className="">
          <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs.
          1 S. 1 lit. f) DSGVO).
        </li>
      </ul>
      <p>
        <strong>
          Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
        </strong>
      </p>
      <ul className="m-elements">
        <li>
          <strong>Instagram: </strong>Soziales Netzwerk;{" "}
          <strong>Dienstanbieter:</strong> Meta Platforms Ireland Limited,
          Merrion Road, Dublin 4, D04 X2K5, Irland;{" "}
          <span className="">
            <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
            Abs. 1 S. 1 lit. f) DSGVO);{" "}
          </span>
          <strong>Website:</strong>
          <a href="https://www.instagram.com" target="_blank">
            https://www.instagram.com
          </a>
          ; <strong>Datenschutzerklärung:</strong>{" "}
          <a href="https://instagram.com/about/legal/privacy" target="_blank">
            https://instagram.com/about/legal/privacy
          </a>
          . <strong>Grundlage Drittlandtransfers:</strong> Data Privacy
          Framework (DPF).
        </li>
      </ul>
    </Box>
  );
}

export default DataProtectionDeclarationPage;
